import Vue from 'vue'
import App from './App.vue'
import 'babel-polyfill'
import router from './router/router'
import store from './store/index'
import FastClick from 'fastclick'
import VueLazyload from 'vue-lazyload'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import Register from './components/register'
import VueResource from 'vue-resource'
import { encryptString, decodeString } from './plugins/encode'
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
Vue.use(VueResource)
Vue.use(Element)
// Vue.use(Register)
Vue.prototype.encryptString = encryptString
Vue.prototype.decodeString = decodeString
Vue.prototype.bus = new Vue()
FastClick.attach(document.body);
Vue.config.productionTip = false
// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   error: 'dist/error.png',
//   loading: 'dist/loading.gif',
//   attempt: 1
// })

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')