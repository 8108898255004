
function checkName(name) {
  if (name.length == 0) {
    return false;
  }
  if (!(/[0-9]/).test(name)) {
    return true;
  }
}

// 判断手机号格式
function checkPhone(mobile) {
  if (mobile.length == 0) {
    return false;
  }
  if (mobile.length != 11) {
    return false;
  }
  // var myreg = /^0?(13[0-9]|15[0-9]|17[013678]|18[0-9]|14[57]|19[0-9]|18[0-9])[0-9]{8}$/;
  var myreg = /^1(3|4|5|6|7|8|9)\d{9}$/;
  if (!myreg.test(mobile)) {
    return false;
  }
  return true;
}
// 密码验证格式
function checkPwd(pwd){
  if(pwd.length == 0) {
    return false;
  }
  // var reg =/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
  var reg=/^[0-9a-zA-Z]{6,20}$/
  var re = new RegExp(reg)
  if (re.test(pwd)) { 
      return true;
  }else{ 
      return false; 
  }
}
// 验证身份证号
function IdnumberCheck(code) {
  var city = { 11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江 ", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北 ", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏 ", 61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外 " };
  var tip = "";
  var pass = true;
  var reg = /(^\d{15}$)|(^[1-9]\d{5}((1[89]|20)\d{2})(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}[\dx|X]$)/; 
  if (!code || !reg.test(code)) {
    tip = "身份证号格式错误";
    pass = false;
  }

  else if (!city[code.substr(0, 2)]) {
    tip = "地址编码错误";
    pass = false;
  }
  else {
    //18位身份证需要验证最后一位校验位
    if (code.length == 18) {
      code = code.split('');
      //∑(ai×Wi)(mod 11)
      //加权因子
      var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      //校验位
      var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
      var sum = 0;
      var ai = 0;
      var wi = 0;
      for (var i = 0; i < 17; i++) {
        ai = code[i];
        wi = factor[i];
        sum += ai * wi;
      }
      var last = parity[sum % 11];
      if (parity[sum % 11] != code[17].toUpperCase()) {
        tip = "校验位错误";
        pass = false;
      }
    }
  }
  return pass;
}
//获取身份证中的生日
function getBirthDay(psidno){
  var birthdayno, birthdaytemp
  if (psidno.length == 18) {
    birthdayno = psidno.substring(6, 14)
  } else if (psidno.length == 15) {
    birthdaytemp = psidno.substring(6, 12)
    birthdayno = "19" + birthdaytemp
  } else {
    alert("错误的身份证号码，请核对！")
    return false
  }
  var birthday = birthdayno.substring(0, 4) + "-" + birthdayno.substring(4, 6) + "-" + birthdayno.substring(6, 8)
  return birthday    
}
//获取身份证性别
function getSex(psidno){
  var sexno, sex
  if (psidno.length == 18) {
    sexno = psidno.substring(16, 17)
  } else if (psidno.length == 15) {
    sexno = psidno.substring(14, 15)
  } else {
    alert("错误的身份证号码，请核对！")
    return false
  }
  var tempid = sexno % 2;
  if (tempid == 0) {
    sex = '女'
  } else {
    sex = '男'
  }
  return sex
}
//邮箱验证
function validateEmail(email) {
  //对电子邮件的验证
  var myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
  if (!myreg.test(email)){
    return false;
      }
      return true;
}

function checkEmoji(substring) {
  if (substring) {
    var reg = new RegExp("[~#^$@%&!?%*]", 'g');
    if (substring.match(reg)) {
      return true;
    }
    for (var i = 0; i < substring.length; i++) {
      var hs = substring.charCodeAt(i);
      if (0xd800 <= hs && hs <= 0xdbff) {
        if (substring.length > 1) {
          var ls = substring.charCodeAt(i + 1);
          var uc = ((hs - 0xd800) * 0x400) + (ls - 0xdc00) + 0x10000;
          if (0x1d000 <= uc && uc <= 0x1f77f) {
            return true;
          }
        }
      } else if (substring.length > 1) {
        var ls = substring.charCodeAt(i + 1);
        if (ls == 0x20e3) {
          return true;
        }
      } else {
        if (0x2100 <= hs && hs <= 0x27ff) {
          return true;
        } else if (0x2B05 <= hs && hs <= 0x2b07) {
          return true;
        } else if (0x2934 <= hs && hs <= 0x2935) {
          return true;
        } else if (0x3297 <= hs && hs <= 0x3299) {
          return true;
        } else if (hs == 0xa9 || hs == 0xae || hs == 0x303d || hs == 0x3030
          || hs == 0x2b55 || hs == 0x2b1c || hs == 0x2b1b
          || hs == 0x2b50) {
          return true;
        }
      }
    }
  }
};
// 0——9自动前面加0
function formatNumber(n) {
  const str = n.toString();
  return str[1] ? str : `0${str}`;
}
// 年月日时分秒
function formatTime(date, daySign, timeSign) {
  daySign = daySign || '/';
  timeSign = timeSign || ':';
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const t1 = [year, month, day].map(formatNumber).join(daySign);
  const t2 = [hour, minute, second].map(formatNumber).join(timeSign);

  return `${t1} ${t2}`
}
//时间戳转年月日
function getDate(str){  
  var oDate = new Date(str),
  oYear = oDate.getFullYear(),  
  oMonth = oDate.getMonth()+1,  
  oDay = oDate.getDate(),  
  oHour = oDate.getHours(),  
  oMin = oDate.getMinutes(),  
  oSec = oDate.getSeconds(),  
  oTime = oYear +'年'+ getzf(oMonth) +'月'+ getzf(oDay) +'日'+ getzf(oHour) +'时'+ getzf(oMin) +'分'+getzf(oSec)+'秒';//最后拼接时间
  return oTime;  
};
//时间戳转年月日
function getDate2(str){  
  var oDate = new Date(str),
  oYear = oDate.getFullYear(),  
  oMonth = oDate.getMonth()+1,  
  oDay = oDate.getDate(),  
  oHour = oDate.getHours(),  
  oMin = oDate.getMinutes(),  
  oSec = oDate.getSeconds(),  
  oTime = oYear +'-'+ getzf(oMonth) +'-'+ getzf(oDay) +' '+ getzf(oHour) +':'+ getzf(oMin) +':'+getzf(oSec);//最后拼接时间
  return oTime;  
};
  //补0操作  
function getzf(num){  
    if(parseInt(num) < 10){  
        num = '0'+num;  
    }  
    return num;  
}
// 数字每三位逗号分隔
 function numDivide(num) {
  return num.toFixed(0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
}
//  ——  几秒前  几分钟前  几小时前  昨天  前天   月日 年月日
function farawayTime(recordTime) {
  var dateTimeStamp = Date.parse(recordTime.replace(/-/gi,"/"));
  var minute = 1000 * 60;
  var hour = minute * 60;
  var day = hour * 24;
  var result;
  var halfamonth = day * 15;
  var month = day * 30;
  var year = month *12;
  var now = new Date().getTime();
  var diffValue = now - dateTimeStamp;
  if(diffValue < 0){
      return;
  }
  var yearC = diffValue/year;
  var monthC =diffValue/month;
  var weekC =diffValue/(7*day);
  var dayC =diffValue/day;
  var hourC =diffValue/hour;
  var minC =diffValue/minute;
  if(yearC>1){
      result = v;
  }
  else if(dayC>=4){
      result= v.substr(5,16);
  }
  else if(dayC>=1){
      result=parseInt(dayC) +"天前";
  }
  else if(hourC>=1){
      result=parseInt(hourC) +"小时前";
  }
  else if(minC>=1){
      result=parseInt(minC) +"分钟前";
  }
  return result;
}
//字符超出指定个数显示...
function  filterStrlen(str,n){
  return str.length>n ? str.substr(0,n)+'...' : v;
}
// 倒计时
function downTime(time) {//time是结束时间
  var timeEnd = new Date(time).getTime();
  var timesNow = new Date().getTime();//当前时间
  var leftTime = timeEnd - timesNow;//剩余时间
  var leftsecond = parseInt(leftTime / 1000);//剩余秒数
  var day_1 = Math.floor(leftsecond / (60 * 60 * 24));//剩余天数
  var hour = Math.floor((leftsecond - day_1 * 24 * 60 * 60) / 3600);//剩余小时
  var minute = Math.floor((leftsecond - day_1 * 24 * 60 * 60 - hour * 60 * 60) / 60);//剩余分钟
  var second = Math.floor(leftsecond - day_1 * 24 * 3600 - hour * 3600 - minute * 60);//剩余秒数
  hour = checkTime(hour);
  minute = checkTime(minute);
  second = checkTime(second);
  var str = `${day_1}天${hour}时${minute}分${second}秒`;
  return str;
  function checkTime(i) { //将0-9的数字前面加上0，例1变为01
    if (i < 10) {
    i = "0" + i;
    }
  return i;
  }
}
// 一分钟倒计时
function settime(that) {
  if (countdown == 0) {
    countdown = 60;
    return;
  } else {
    countdown--;
  }
  setTimeout(()=> {
    this.settime(that)
  }, 1000)
}
// 时间戳转化为年月日时分秒
function formatDuring(shijiancha) {
  var days = shijiancha / 1000 / 60 / 60 / 24;
  var daysRound = checkTime(Math.floor(days));
  var hours = shijiancha / 1000 / 60 / 60 - (24 * daysRound);
  var hoursRound = checkTime(Math.floor(hours));
  var minutes = shijiancha / 1000 / 60 - (24 * 60 * daysRound) - (60 * hoursRound);
  var minutesRound = checkTime(Math.floor(minutes));
  var seconds = shijiancha / 1000 - (24 * 60 * 60 * daysRound) - (60 * 60 * hoursRound) - (60 * minutesRound);
  var secondsRound = checkTime(Math.floor(seconds));
  return daysRound + "天" + hoursRound + "小时" + minutesRound + "分" + secondsRound + "秒";
  function checkTime(i) { //将0-9的数字前面加上0，例1变为01
    if (i < 10) {
    i = "0" + i;
    }
  return i;
  }
}
// 根据指定月份获取当月的起始日期和结束日期
function getMonthDate(year, month) {
  var monthDate = {}
  var year = year
  var month = month
  var startDate = moment([year, month - 1])
  var endDate = moment(startDate).endOf('month')
  monthDate.startDate = startDate.format('YYYY-MM-DD')
  monthDate.endDate = endDate.format('YYYY-MM-DD')
  return monthDate
}
//获取地址栏参数
function getUrlParam (param, callback) {
  let search = location.hash.split('?')[1]
  if (search != undefined) {
    let params = search.split('&')
    for (let i = 0; i < params.length; i++) {
      if (param === paraArr[i].split('=')[0]) {
        return window.decodeURIComponent(paraArr[i].split('=')[1])
      }
    }
  }
  return ''
}
// 下载APP
function downloadApp() {
  var data = {};
  linkedme.init("99fbdc474cea39f4e2c83ecd87e958ee", data, function () {});
  data.params = '{"path":"run/malasong","id":"123456"}';
  data.type = "live";
  data.tags = "a";
  data.ios_custom_url = "https://www.marathonbm.com/down/marathon";
  //data.ios_direct_open = "true"; 
  data.android_custom_url = "https://www.marathonbm.com/down/marathon";
  //data.android_direct_open = "true";    
  linkedme.link(data, function (err, response) {
    if (err) {
      // 生成深度链接失败，返回错误对象err                    
    } else {
      // document.getElementById("openAPP1").href=response.url;
      window.location.href = response.url
    }
  }, false);
}
// 让元素居中显示
const attrCenter = (ele) => {
  $(ele).css({
    position: 'absolute',
    left: (document.body.clientWidth - $(ele).width()) / 2 + document.body.scrollLeft,
    top: ((document.body.clientHeight - $(ele).height()) / 2 + document.body.scrollTop) <
      0 ? 10 : (document.body.clientHeight - $(ele).height()) / 2 + document.body.scrollTop,
  });
}
// 时间显示  ——  几秒前  几分钟前  几小时前  昨天  前天   其他
const changeTime = (recordTime, type) => {
  let todayTime = new Date()
  // let oldTime = new Date(recordTime)
  let time = typeof recordTime == 'string' ? Date.parse(recordTime.replace(/-/g, "/")) : recordTime
  let oldTime = new Date(time)
  let todayTime_year = todayTime.getFullYear()
  let todayTime_month = todayTime.getMonth() + 1
  let todayTime_day = todayTime.getDate()
  let oldTime_year = oldTime.getFullYear()
  let oldTime_month = oldTime.getMonth() + 1
  let oldTime_day = oldTime.getDate()
  let oldTime_hour = oldTime.getHours() < 10 ? '0' + oldTime.getHours() : oldTime.getHours()
  let oldTime_minutes = oldTime.getMinutes() < 10 ? '0' + oldTime.getMinutes() : oldTime.getMinutes()
  const duration = (todayTime - oldTime) / 1000;
  let displayTime = ''
  if (duration < 60) {
    // displayTime = `${Math.round(Math.max(duration, 1))} 秒前`;
    displayTime = `刚刚`;
  } else if (duration < 60 * 60) {
    displayTime = `${Math.round(duration / 60)} 分钟前`;
  } else if (duration < 60 * 60 * 24) {
    displayTime = `${Math.round(duration / 60 / 60)} 小时前`;
  } else if (todayTime_year == oldTime_year && todayTime_month == oldTime_month && todayTime_day - oldTime_day == 1) {
    displayTime = '2天前'
  } else if (todayTime_year == oldTime_year && todayTime_month == oldTime_month && todayTime_day - oldTime_day == 2) {
    displayTime = '3天前'
  } else {
    // if (typeof recordTime == 'number') {
    //   displayTime = oldTime_year + '/' + oldTime_month + '/' + oldTime_day + ' ' + oldTime_hour + ':' + oldTime_minutes
    // } else {
    //   displayTime = recordTime
    // }
    if (todayTime_year == oldTime_year) {
      displayTime = oldTime_month + '月' + oldTime_day + '日' + ' ' + oldTime_hour + ':' + oldTime_minutes
    } else {
      displayTime = oldTime_year + '年' + oldTime_month + '月' + oldTime_day + '日' + ' ' + oldTime_hour + ':' + oldTime_minutes
    }
    
  }
  if(type == 'time') {
    displayTime = oldTime_year + '-' + oldTime_month + '-' + oldTime_day + ' ' + oldTime_hour + ':' + oldTime_minutes
  }
  return displayTime;
}


import moment from 'moment'
export {
  checkName,
  checkPhone,
  checkPwd,
  IdnumberCheck,
  getBirthDay,
  getSex,
  validateEmail,
  checkEmoji,
  formatTime,
  formatNumber,
  numDivide,
  farawayTime,
  filterStrlen,
  downTime,
  settime,
  formatDuring,
  getDate,
  getDate2,
  getMonthDate,
  getUrlParam,
  downloadApp,
  attrCenter,
  changeTime,
}