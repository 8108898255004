import { Base64 } from 'js-base64'


// 加密
const encryptString = (name) => {
  return Base64.encode(name)
}

// 解密
const decodeString = (name) => {
  let decodeName = Base64.decode(name) || ''

    return decodeName;
}

export { encryptString, decodeString }
