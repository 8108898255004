import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Index from '../views/Index/index'
// import mIndex from '../mobileViews/mIndex/mIndex'
let routes;

// 判断不同设备设置不同路由
// if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
//   // 手机路由
//   routes = [
//     {
//       path: '/',
//       // name:'mIndex',
//       component: mIndex,
//       meta:{
//         title:'手机版首页',
//         keepAlive: true
//       }
//     },
//     {
//       path: '/event',
//       // name: 'mEvent',
//       component: () => import('../mobileViews/mEvent/mEvent.vue'),
//       meta: {
//         title: '手机版赛事'
//       }
//     },
//   ]
// } else {
  // pc路由
  routes = [
    {
      path: '/',
      name:'Index',
      component: Index,
      meta:{
        title:'马拉松报名'
      }
    },
   
   
  ]
// }
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to,from, next) => {
  if (to.meta.title) { 
    document.title = to.meta.title;
  }
  // console.log(to, from)
  next();
})

export default router;